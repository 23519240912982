







import Vue from 'vue';
export default {};
